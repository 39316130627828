import "../scss/main.scss";
import 'slick-carousel';

$(function(){

    // Detect si la barre d'admin est présente
    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar = 0; }

    // BT retour vers le haut
    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    // BT menu
    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('#nav-main').toggleClass('open');
        $('body').toggleClass('openMenu');

    });

    $('#nav-main > li.menu-item-has-children').each(function(){
        var $dropdownMenu = $(this).find('ul.sub-menu');
        $dropdownMenu.slideUp();
        $(this).removeClass('open');

        $(this).on('click', function(){
            if ($dropdownMenu.is(":visible")){
                $dropdownMenu.slideUp();
                $(this).removeClass('open');
            } else {
                $dropdownMenu.slideDown();
                $(this).addClass('open');
                $('body').addClass('openMenu');
            }
        })

        // $(this).on('mouseenter', function(){
        //     $dropdownMenu.slideUp();
        //     $(this).removeClass('open');
        // }).on( "mouseleave", function(){
        //     $dropdownMenu.slideDown();
        //     $(this).addClass('open');
        //     $('body').addClass('openMenu');
        // });
    });

    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();
});

function actionsScroll(){
    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling').removeClass('notscroll');
        
    } else {
        $('body').addClass('notscroll').removeClass('scrolling');
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });
}

function sliders(){
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}